<template>
  <main class="container my-3 text-center">
    <h1 class="raleway-bold">404</h1>
    <p>
      Da hat wohl jemand einen falschen Link weitergeben.
    </p>
    <router-link class="btn btn-primary shadow rounded-pill raleway-black" to="/"
      >» Zurück</router-link
    >
  </main>
</template>
